@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;800;900&family=Press+Start+2P&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;800;900&family=Press+Start+2P&display=swap);
*,*::before,*::after{box-sizing:border-box}html,body,#root{margin:0;padding:0;width:100%;height:100%;background-color:#bde6ff}h1,h2,h3,h4,h5,h6{font-family:"Press Start 2P",cursive;margin:0;padding:0}p{font-family:"Nunito",sans-serif;margin:0;padding:0}
.scoreboard{width:100%;padding:.5rem;display:flex;justify-content:space-between;align-items:center}.scoreboard>*{font-size:.75rem;line-height:.75rem}.game{width:100%}.remoteControls{display:flex;flex-direction:column;align-items:center}.remoteControls__row{display:flex}.remoteControls__row>button{border:4px solid #007ecc;background:none;width:58px;height:58px;margin:.2rem;display:flex;justify-content:center;align-items:center;cursor:pointer}
.layout{width:100%;height:100%;display:flex;flex-direction:column;align-items:center;background-image:url(/static/media/world_map.30612bff.png);background-size:cover}.layout__wrapper{width:100%;max-width:500px;height:100%;display:flex;flex-direction:column;align-items:center;justify-content:space-evenly}
#renderer{width:100%;height:100%;display:flex;justify-content:center}#renderer>canvas{border:12px solid #007ecc}
.score{display:flex;flex-direction:column;justify-content:center}.score>p{font-family:"Press Start 2P",cursive;text-align:center}.score__header{font-size:.625rem;line-height:1rem}.score__counter{font-size:1.125rem;line-height:2rem;color:#007ecc}.score__value{font-size:1.685rem;line-height:2rem;margin-top:1rem}.buttons{width:100%;max-width:306px;display:flex;flex-wrap:wrap;padding:0 2rem}.buttons>button{margin:.4rem}
.button{border:none;border-radius:.25rem;font-family:"Press Start 2P",cursive;font-style:normal;font-weight:normal;font-size:1rem;line-height:138.5%;display:flex;justify-content:center;align-items:center;padding:.5rem 1rem;cursor:pointer}.button--block{width:100%}.button--primary{background:#33b1ff;color:#fff;box-shadow:0px 4px 4px rgba(0,0,0,.25)}.button--secondary{background:#fff;color:#009eff;box-shadow:0px 4px 4px rgba(0,0,0,.25)}.button--CTA{font-family:"Nunito",sans-serif;font-size:1.1rem;font-weight:900;background:#ff7b00;color:#fff;box-shadow:0px 4px 4px rgba(0,0,0,.25)}
.logo{max-width:143px}@media screen and (min-width: 375px){.logo{max-width:176px}}@media screen and (min-width: 768px){.logo{max-width:306px}}
.welcome{padding:1rem}.welcome>p{font-family:"Press Start 2P",cursive;font-size:.75rem;line-height:1rem;text-align:center;margin:.5rem 0}
.tutorial{display:flex;flex-direction:column;align-items:center;justify-content:space-evenly;padding:1rem}.tutorial>*{padding:.5rem 0}.tutorial>p{font-family:"Press Start 2P",cursive;font-size:.75rem;line-height:1rem;text-align:center;margin:.5rem 0}
