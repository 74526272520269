#renderer {
    width: 100%;
    height: 100%;
    
    display: flex;
    justify-content: center;
    
    & > canvas {
        border: 12px solid #007ECC
    }
}