.button {
    border: none;
    border-radius: .25rem;
    
    font-family: 'Press Start 2P', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 138.5%;

    display: flex;
    justify-content: center;
    align-items: center;
    
    padding: .5rem 1rem;

    cursor: pointer;

    &--block {
        width: 100%;
    }

    &--primary {
        background: #33B1FF;
        color: white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &--secondary {
        background: white;
        color: #009EFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    &--CTA {
        font-family: 'Nunito', sans-serif;
        font-size: 1.1rem;
        font-weight: 900;
        
        background: #FF7B00;
        color: white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}