@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;800;900&family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;800;900&family=Press+Start+2P&display=swap');

*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body,
#root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    background-color: #BDE6FF;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Press Start 2P', cursive;
    
    margin: 0;
    padding: 0;
}

p {
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
}