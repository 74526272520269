.layout {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    align-items: center;

    background-image: url(../assets/world_map.png);
    background-size: cover;
    
    &__wrapper {
        width: 100%;
        max-width: 500px;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
}