.scoreboard {
    width: 100%;
    padding: 0.5rem;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    & > * {
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
}

.game {
    width: 100%;
}

.remoteControls {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__row {
        display: flex;

        & > button {
            border: 4px solid #007ECC;
            background: none;
            width: 58px;
            height: 58px;
            margin: 0.2rem;

            display: flex;
            justify-content: center;
            align-items: center;

            cursor: pointer;
        }
    }

}