.score {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    & > p {
        font-family: 'Press Start 2P', cursive;
        text-align: center;
    }
    
    &__header {
        font-size: 0.625rem;
        line-height: 1rem;
    }
    
    &__counter {
        font-size: 1.125rem;
        line-height: 2rem;
        color: #007ECC;
    }

    &__value {
        font-size: 1.685rem;
        line-height: 2rem;
        margin-top: 1rem;
    }
}

.buttons {
    width: 100%;
    max-width: 306px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 2rem;

    & > button {
        margin: 0.4rem;
    }
}