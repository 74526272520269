.tutorial {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    padding: 1rem;

    & > * {
        padding: .5rem 0;
    }

    & > p {
        font-family: 'Press Start 2P', cursive;
        font-size: 0.75rem;
        line-height: 1rem;

        text-align: center;

        margin: 0.5rem 0;
    }
}