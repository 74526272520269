.welcome {
    padding: 1rem;
    
    & > p {
        font-family: 'Press Start 2P', cursive;
        font-size: 0.75rem;
        line-height: 1rem;

        text-align: center;

        margin: 0.5rem 0;
    }
}