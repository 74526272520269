.logo {
    max-width: 143px;

    @media screen and (min-width: 375px) {
        max-width: 176px;
    }

    @media screen and (min-width: 768px) {
        max-width: 306px;
    }
}